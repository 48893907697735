import * as styles from './LargeImages.module.scss'

import React, { useMemo } from 'react'

import { useIndexState } from 'src/utils/useIndexState'

import { RadialProgressIndicator } from './RadialProgressIndicator'
import classNames from 'classnames'

interface Props {
  logs: {
    id: string
    frontmatter: {
      date: string
      cubeCount: number
    }
  }[]
  focusIndex: ReturnType<typeof useIndexState>
  maxCubes: number
}

export const LargeImages: React.FC<Props> = (props) => {
  const { focusIndex, maxCubes } = props

  const logs = useMemo(() => {
    return props.logs.map((log) => ({
      ...log,
      formattedDate: new Date(log.frontmatter.date).toLocaleDateString(
        'en-us',
        {
          year: 'numeric',
          month: 'long',
        },
      ),
    }))
  }, [props.logs])

  const focusedLog = logs[focusIndex.value]

  return (
    <div>
      <div className={styles.controls}>
        <button
          type="button"
          className={styles.navButton}
          onClick={focusIndex.decrement}
          disabled={!focusIndex.canDecrement}
        >
          &larr;
        </button>

        <div className={styles.countContainer}>
          <div className={styles.date}>{focusedLog.formattedDate}</div>

          <div className={styles.cubeCount}>
            <div>{focusedLog.frontmatter.cubeCount.toLocaleString()} Cubes</div>
            <RadialProgressIndicator
              fraction={focusedLog.frontmatter.cubeCount / maxCubes}
            />
          </div>
        </div>

        <button
          type="button"
          className={styles.navButton}
          onClick={focusIndex.increment}
          disabled={!focusIndex.canIncrement}
        >
          &rarr;
        </button>
      </div>

      <div className={styles.indexIndicator}>
        {logs.map((log, index) => (
          <button
            key={index}
            type="button"
            className={classNames(styles.indexIndicatorItem, {
              [styles.active]: index === focusIndex.value,
            })}
            onClick={() => focusIndex.set(index)}
            title={log.formattedDate}
          >
            <div className={styles.indexIndicatorItemIcon}>
              {log.formattedDate}
            </div>
          </button>
        ))}
      </div>

      <div className={styles.mapContainer}>
        <img
          className={styles.image}
          src={`/resources/cube-map/changelog/cube-map-${
            focusedLog.frontmatter!.date
          }.png`}
        />
      </div>
    </div>
  )
}
