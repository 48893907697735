import React from 'react'

interface Props {
  fraction: number
}

export const RadialProgressIndicator: React.FC<Props> = (props) => {
  const { fraction } = props
  const size = 11
  const strokeWidth = 2.25
  const circleRadius = size / 2 - strokeWidth / 2

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      style={{ display: 'inline' }}
    >
      <circle
        cx={0}
        cy={0}
        r={circleRadius}
        style={{ stroke: 'rgba(var(--text-color-rgb), .2)' }}
        transform={`translate(${size / 2} ${size / 2}) rotate(-90)`}
        fill="none"
        strokeWidth={strokeWidth}
      />

      <circle
        cx={0}
        cy={0}
        r={circleRadius}
        style={{ stroke: 'var(--text-color)' }}
        strokeDasharray={`${fraction * circleRadius * 2 * Math.PI} 1000`}
        transform={`translate(${size / 2} ${size / 2}) rotate(-90)`}
        fill="none"
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
