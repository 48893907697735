import React from 'react'

export const Large: React.FC = () => {
  return (
    <svg viewBox="0 0 16 16">
      <defs>
        <style>
          {`
      .cls-1 {
        fill: none;
        stroke: var(--text-color);
        stroke-linecap: round;
        stroke-linejoin: round;
      }`}
        </style>
      </defs>

      <rect className="cls-1" x="3.73" y="3.73" width="8.54" height="8.54" />
      <polyline className="cls-1" points="13.81 6.29 14.82 8 13.81 9.71" />
      <polyline className="cls-1" points="2.19 6.29 1.18 8 2.19 9.71" />
      <line className="cls-1" x1="8.23" y1="5.97" x2="9.9" y2="5.97" />
      <line className="cls-1" x1="5.66" y1="8" x2="8.23" y2="8" />
      <line className="cls-1" x1="7.83" y1="9.5" x2="9.5" y2="9.5" />
    </svg>
  )
}
