import React from 'react'

export const Small: React.FC = () => {
  return (
    <svg viewBox="0 0 16 16">
      <defs>
        <style>
          {`
      .cls-1 {
        fill: none;
        stroke: var(--text-color);
        stroke-linecap: round;
        stroke-linejoin: round;
      }`}
        </style>
      </defs>

      <rect className="cls-1" x="1.21" y="3.14" width="3.4" height="3.4" />
      <rect className="cls-1" x="6.3" y="3.14" width="3.4" height="3.4" />
      <rect className="cls-1" x="11.39" y="3.14" width="3.4" height="3.4" />
      <rect className="cls-1" x="1.21" y="8.24" width="3.4" height="3.4" />
      <rect className="cls-1" x="6.3" y="8.24" width="3.4" height="3.4" />
      <rect className="cls-1" x="11.39" y="8.24" width="3.4" height="3.4" />
    </svg>
  )
}
