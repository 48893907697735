import { clamp } from 'lodash'
import { useCallback, useMemo, useState } from 'react'

export function useIndexState(options: {
  initial?: number
  min?: number
  max: number
}) {
  const { initial = 0, min = 0, max } = options

  const [value, setValue] = useState(initial)

  const set = useCallback(
    (index: number) => {
      setValue(clamp(index, min, max))
    },
    [max, min],
  )

  const increment = useCallback(() => {
    setValue((value) => clamp(value + 1, min, max))
  }, [max, min])

  const decrement = useCallback(() => {
    setValue((value) => clamp(value - 1, min, max))
  }, [max, min])

  const canIncrement = value < max
  const canDecrement = value > min

  return useMemo(
    () => ({
      value,
      set,
      increment,
      decrement,
      canIncrement,
      canDecrement,
    }),
    [decrement, increment, set, value, canIncrement, canDecrement],
  )
}
