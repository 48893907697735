import * as styles from './Notes.module.scss'

import React from 'react'
import { reverse, sortBy } from 'lodash'

import * as Typography from 'components/typography'

import Markdown from 'components/markdown/Markdown'

interface Props {
  logs: {
    id: string
    body: string
    frontmatter: {
      date: string
      cubeCount: number
      formattedDate: string
    }
    wordCount: {
      words: number
    }
  }[]
}

export const Notes: React.FC<Props> = (props) => {
  const logs = reverse(
    sortBy(
      props.logs.filter((log) => log.wordCount.words > 0),
      (log) => log.frontmatter.date,
    ),
  )

  return (
    <div className={styles.container}>
      {logs.map((log, index) => (
        <div key={log.id}>
          {index > 0 && <Typography.HorizontalRule />}

          <h2>
            <Typography.TertiaryHeading>
              {log.frontmatter.formattedDate}
            </Typography.TertiaryHeading>
          </h2>

          <div className={styles.mapImageContainer}>
            <img
              className={styles.mapImage}
              src={`/resources/cube-map/changelog/cube-map-${
                log.frontmatter!.date
              }.png`}
            />
          </div>

          <div className={styles.cubeCount}>
            {log.frontmatter.cubeCount.toLocaleString()} Cubes
          </div>

          <div>
            <Markdown>{log.body}</Markdown>
          </div>
        </div>
      ))}
    </div>
  )
}
