import * as styles from './HistoricalRenders.module.scss'

import React, { useMemo, useState } from 'react'
import { sortBy } from 'lodash'
import classNames from 'classnames'

import { useIndexState } from 'src/utils/useIndexState'

import * as Icons from './icons'
import { RadialProgressIndicator } from './RadialProgressIndicator'
import { LargeImages } from './LargeImages'

interface Props {
  logs: {
    id: string
    frontmatter: {
      date: string
      cubeCount: number
    }
  }[]
}

const viewModeClasses: Record<string, string> = {
  small: styles.small,
  medium: styles.medium,
  large: styles.large,
}

export const HistoricalRenders: React.FC<Props> = (props) => {
  const logs = sortBy(props.logs, (log) => log.frontmatter.date)

  const maxCubes = useMemo(() => {
    return Math.max(...logs.map((log) => log.frontmatter.cubeCount))
  }, [logs])

  const focusIndex = useIndexState({ max: logs.length - 1 })

  const [viewMode, setViewMode] = useState('small')

  return (
    <div>
      <div className={styles.controls}>
        <div className={styles.modeButtons}>
          <button
            type="button"
            className={classNames(styles.modeButton, {
              [styles.active]: viewMode === 'small',
            })}
            onClick={() => setViewMode('small')}
          >
            <Icons.Small />
          </button>
          <button
            type="button"
            className={classNames(styles.modeButton, {
              [styles.active]: viewMode === 'medium',
            })}
            onClick={() => setViewMode('medium')}
          >
            <Icons.Medium />
          </button>
          <button
            type="button"
            className={classNames(styles.modeButton, {
              [styles.active]: viewMode === 'large',
            })}
            onClick={() => setViewMode('large')}
          >
            <Icons.Large />
          </button>
        </div>
      </div>

      {viewMode === 'large' ? (
        <LargeImages logs={logs} focusIndex={focusIndex} maxCubes={maxCubes} />
      ) : (
        <div className={classNames(styles.mapGrid, viewModeClasses[viewMode])}>
          {logs.map((log) => (
            <div key={log.id} className={styles.log}>
              <div className={styles.mapImageContainer}>
                <img
                  className={styles.mapImage}
                  src={`/resources/cube-map/changelog/cube-map-${
                    log.frontmatter!.date
                  }.png`}
                />
              </div>

              <div className={styles.countContainer}>
                <div className={styles.date}>
                  {new Date(log.frontmatter.date).toLocaleDateString(
                    'en-us',
                    viewMode === 'small'
                      ? {
                          year: '2-digit',
                          month: 'short',
                        }
                      : {
                          year: 'numeric',
                          month: 'long',
                        },
                  )}
                </div>

                <div className={styles.cubeCount}>
                  <div>{log.frontmatter.cubeCount.toLocaleString()} Cubes</div>
                  <RadialProgressIndicator
                    fraction={log.frontmatter.cubeCount / maxCubes}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
