// title: Cube Map Changelog
// frameCardName: Faces of the Past
// frameCardSet: scg

import * as styles from './changelog.module.scss'

import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout/Layout'
import PageMetaData from 'components/layout/PageMetaData'

import { Changelog } from 'components/maps/cube-map-changelog/Changelog'

interface Props extends PageProps<Queries.CubeMapChangelogPageQuery> {
  pageContext: any
}

const ArticlesPage: React.FC<Props> = (props) => {
  const { nodes } = props.data.allMdx

  return (
    <Layout
      {...props.pageContext}
      accentColor="#3587C5"
      includeNewsletterSignup
    >
      <div className={styles.container}>
        <Changelog logs={nodes as any} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CubeMapChangelogPage {
    allMdx(
      filter: { fields: { type: { eq: "map-changelog" } } }
      sort: { frontmatter: { date: DESC } }
    ) {
      nodes {
        id
        body
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          formattedDate: date(formatString: "MMMM Do, YYYY")
          cubeCount
        }
        wordCount {
          words
        }
      }
    }
  }
`

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'Cube Map Changelog — Lucky Paper',
        description:
          'Notes and observations on how the shape of the Cube universe evolves, and new features in the Cube Map.',
        ogImageURL: 'https://luckypaper.co/og-images/Lucky-Paper-Cube-Map.jpg',
      }}
    />
  )
}

export default ArticlesPage
