import React from 'react'

export const Medium: React.FC = () => {
  return (
    <svg viewBox="0 0 16 16">
      <defs>
        <style>
          {`
      .cls-1 {
        fill: none;
        stroke: var(--text-color);
        stroke-linecap: round;
        stroke-linejoin: round;
      }`}
        </style>
      </defs>
      <rect className="cls-1" x="1.07" y="3.46" width="5.82" height="5.82" />
      <rect className="cls-1" x="9.11" y="3.46" width="5.82" height="5.82" />
      <line className="cls-1" x1="1.4" y1="11.2" x2="6.57" y2="11.2" />
      <line className="cls-1" x1="9.43" y1="11.2" x2="14.6" y2="11.2" />
    </svg>
  )
}
