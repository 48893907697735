// extracted by mini-css-extract-plugin
export var active = "LargeImages-module--active--89fa3";
export var controls = "LargeImages-module--controls--8f550";
export var countContainer = "LargeImages-module--countContainer--ba6c7";
export var cubeCount = "LargeImages-module--cubeCount--1a0b4";
export var date = "LargeImages-module--date--4c1a9";
export var image = "LargeImages-module--image--dbd41";
export var indexIndicator = "LargeImages-module--indexIndicator--78a54";
export var indexIndicatorItem = "LargeImages-module--indexIndicatorItem--e7346";
export var indexIndicatorItemIcon = "LargeImages-module--indexIndicatorItemIcon--142ba";
export var mapContainer = "LargeImages-module--mapContainer--676be";
export var navButton = "LargeImages-module--navButton--f7dbf";