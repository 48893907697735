// extracted by mini-css-extract-plugin
export var active = "HistoricalRenders-module--active--737ba";
export var controls = "HistoricalRenders-module--controls--07d88";
export var countContainer = "HistoricalRenders-module--countContainer--1b64e";
export var cubeCount = "HistoricalRenders-module--cubeCount--65a6a";
export var date = "HistoricalRenders-module--date--138bf";
export var large = "HistoricalRenders-module--large--33a0b";
export var log = "HistoricalRenders-module--log--c277b";
export var mapGrid = "HistoricalRenders-module--mapGrid--0f332";
export var mapImage = "HistoricalRenders-module--mapImage--8f661";
export var mapImageContainer = "HistoricalRenders-module--mapImageContainer--60b74";
export var maps = "HistoricalRenders-module--maps--fd7c9";
export var medium = "HistoricalRenders-module--medium--1383f";
export var modeButton = "HistoricalRenders-module--modeButton--e5f95";
export var modeButtons = "HistoricalRenders-module--modeButtons--d83d0";
export var small = "HistoricalRenders-module--small--39f55";